html,body{height:100%;}
html,body,h1,h2,h3,h4,h5,h6,div,dl,dt,dd,ul,ol,li,p,blockquote,pre,hr,figure,table,caption,th,td,form,fieldset,legend,input,button,textarea,menu{margin:0;padding:0;}
header,footer,section,article,aside,nav,hgroup,address,figure,figcaption,menu,details{display:block;}
table{border-collapse:collapse;border-spacing:0;}
caption,th{text-align:left;font-weight:normal;}
html,body,fieldset,img,iframe,abbr{border:0;}
i,cite,em,var,address,dfn{font-style:normal;}
[hidefocus],summary{outline:0;}
ul,li{list-style:none;}
h1,h2,h3,h4,h5,h6,small{font-size:100%;}
sup,sub{font-size:83%;}
pre,code,kbd,samp{font-family:inherit;}
q:before,q:after{content:none;}
textarea{overflow:auto;resize:none;}
h1,h2,h3,h4,h5,h6,em,strong,b{font-weight:bold;}
del,ins,u,s,a,a:hover{text-decoration:none;}
body,textarea,input,button,select,keygen,legend{font:12px/1.14 Microsoft YaHei,arial,\5b8b\4f53;color:#333;outline:0;}
body{background:#fff;}
a,a:hover{color:#333;}
*{box-sizing: border-box;}
::-webkit-input-placeholder {
  color: #fff ;
}
:-moz-placeholder {
  color: #fff ;
}

::-moz-placeholder {
  color: #fff ;
}

:-ms-input-placeholder {
  color: #fff ;
}
/* WebKit browsers */
input:focus::-webkit-input-placeholder { color:transparent; }

/* Mozilla Firefox 4 to 18 */
input:focus:-moz-placeholder { color:transparent; }

/* Mozilla Firefox 19+ */
input:focus::-moz-placeholder { color:transparent; }

/* Internet Explorer 10+ */
input:focus:-ms-input-placeholder { color:transparent; }

.hidden{
  visibility: hidden;
}
.skuBox {
  overflow: hidden;
}
.skuBox .topBox {
  display: flex;
  justify-content: space-between;
  height: 60px;
  line-height: 60px;
  margin-left: 34px;
}
.skuBox .topBox .left {
  display: flex;
  justify-content: flex-start;
}
.skuBox .topBox .left .retrunBox a {
  display: flex;
}
.skuBox .topBox .left .retrunBox a span {
  margin-top: 18px;
  display: inline-block;
  width: 13px;
  height: 23px;
  background: url(//cdn-ali-file-shfront.shanhutech.cn/front/web/return_1616046027178.png) no-repeat 0;
}
.skuBox .topBox .left .retrunBox a .textBox {
  color: #333;
  font-size: 18px;
  margin-left: 20px;
}
.skuBox .topBox .left .retrunBox a:hover span {
  background-position-x: -13px;
}
.skuBox .topBox .left .retrunBox a:hover .textBox {
  color: #4cc4ff;
}
.skuBox .topBox .right {
  margin-right: 34px;
}
.skuBox .topBox .right span {
  display: inline-block;
  width: 113px;
  height: 37px;
  background: url(//cdn-ali-file-shfront.shanhutech.cn/front/web/fanhui_1616046199844_1617074224057.png) no-repeat;
  margin-top: 11.5px;
}
.skuBox .topBox .right span:hover {
  background-position-x: -113px;
}
.skuBox .showSku {
  padding-left: 34px;
  display: flex;
  justify-content: flex-start;
}
.skuBox .showSku .leftBox {
  width: 220px;
  background: #FBFBFB;
  border: 1px solid #EFEFEF;
  border-radius: 8px;
}
.skuBox .showSku .leftBox ul li {
  height: 82px;
  line-height: 82px;
  font-size: 18px;
  color: #000;
  padding-left: 18px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
}
.skuBox .showSku .leftBox ul li img {
  width: 36px;
  height: 36px;
  margin-top: 23px;
  margin-left: 30px;
}
.skuBox .showSku .leftBox ul li span {
  margin-left: 20px;
}
.skuBox .showSku .leftBox ul li.active,
.skuBox .showSku .leftBox ul li:hover {
  background: url(//cdn-ali-file-shfront.shanhutech.cn/front/web/hover_1615951494527.png) no-repeat 20px 0;
  background-size: cover;
}
.skuBox .showSku .rightBox {
  margin-left: 42px;
  width: 1000px;
  height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
}
.skuBox .showSku .rightBox::-webkit-scrollbar {
  width: 8px;
}
.skuBox .showSku .rightBox::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #555;
}
.skuBox .showSku .rightBox::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #F5F5F5;
}
.skuBox .showSku .rightBox .titleBox {
  margin-top: 20px;
}
.skuBox .showSku .rightBox .titleBox h3 {
  font-size: 18px;
  color: #000;
}
.skuBox .showSku .rightBox .showList {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;
}
.skuBox .showSku .rightBox .showList .box {
  width: 156px;
  height: 56px;
  background: #FBFBFB;
  border: 1px solid #EFEFEF;
  border-radius: 6px;
  text-align: center;
  line-height: 56px;
  font-size: 15px;
  color: #666;
  cursor: pointer;
  margin-right: 33px;
  margin-bottom: 15px;
}
.skuBox .showSku .rightBox .showList .box.active,
.skuBox .showSku .rightBox .showList .box:hover {
  background: #4CC4FF;
  color: #fff;
}
.skuBox .showSku .rightBox .showList .box:nth-of-type(5n) {
  margin-right: 0;
}
.skuBox.small {
  width: 958px;
  height: 564px;
  overflow: hidden;
}
.skuBox.small .topBox .textBox {
  font-size: 13px;
}
.skuBox.small .showSku .leftBox {
  width: 170px;
}
.skuBox.small .showSku .leftBox li {
  height: 62px;
  line-height: 62px;
  font-size: 15px;
}
.skuBox.small .showSku .leftBox li img {
  width: 24px;
  height: 24px;
  margin-top: 19px;
  margin-left: 30px;
}
.skuBox.small .showSku .leftBox li.active,
.skuBox.small .showSku .leftBox li:hover {
  background-size: contain;
}
.skuBox.small .showSku .rightBox {
  width: 710px;
  height: 500px;
}
.skuBox.small .showSku .rightBox .showList .box {
  width: 130px;
  height: 40px;
  line-height: 40px;
  margin-right: 10px;
  font-size: 14px;
  margin-bottom: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.content {
  width: 1280px;
  background: #fff;
  margin: 0 auto;
  height: 750px;
}
.content.makeBox .topBox {
  display: flex;
  justify-content: space-between;
  height: 60px;
  line-height: 60px;
  margin-left: 34px;
}
.content.makeBox .topBox .leftBox {
  color: #333;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
}
.content.makeBox .topBox .leftBox .retrunBox a {
  display: flex;
}
.content.makeBox .topBox .leftBox .retrunBox a span {
  margin-top: 18px;
  display: inline-block;
  width: 13px;
  height: 23px;
  background: url(//cdn-ali-file-shfront.shanhutech.cn/front/web/return_1616046027178.png) no-repeat 0;
  cursor: pointer;
}
.content.makeBox .topBox .leftBox .retrunBox a .textBox {
  color: #333;
  font-size: 18px;
  margin-left: 20px;
}
.content.makeBox .topBox .leftBox .retrunBox a:hover span {
  background-position-x: -13px;
}
.content.makeBox .topBox .leftBox .retrunBox a:hover .textBox {
  color: #4cc4ff;
}
.content.makeBox .topBox .rightBox {
  padding-top: 15px;
  position: relative;
  margin-right: 30px;
}
.content.makeBox .topBox .rightBox button {
  width: 118px;
  height: 36px;
  background: rgba(76, 196, 255, 0.05);
  border: 1px solid #4CC4FF;
  box-shadow: 0px 2px 4px 0px rgba(141, 216, 255, 0.32);
  border-radius: 6px;
  color: #4CC4FF;
  cursor: pointer;
  line-height: 36px;
}
.content.makeBox .topBox .rightBox input {
  width: 118px;
  position: absolute;
  right: 0;
  height: 36px;
  top: 28px;
  opacity: 0;
  z-index: 3;
  cursor: pointer;
}
.content.makeBox .showBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 571px;
  margin-top: 10px;
}
.content.makeBox .showBox .rotateBox,
.content.makeBox .showBox .zoomBox {
  height: 276px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: -50px;
}
.content.makeBox .showBox .rotateBox .titleBox,
.content.makeBox .showBox .zoomBox .titleBox {
  width: 15px;
  color: #666;
  font-size: 15px;
  margin: 0 10px;
  position: relative;
  top: 40px;
}
.content.makeBox .showBox .rotateBox .rotate,
.content.makeBox .showBox .zoomBox .rotate {
  margin-left: 41px;
  height: 276px;
  margin-right: 41px;
}
.content.makeBox .showBox .rotateBox .rotate span,
.content.makeBox .showBox .zoomBox .rotate span {
  font-size: 15px;
  color: #666;
}
.content.makeBox .showBox .rotateBox .rotate .slider,
.content.makeBox .showBox .zoomBox .rotate .slider {
  margin: 16px 0;
}
.content.makeBox .showBox .demoBox {
  display: flex;
  justify-content: center;
  height: 380px;
  align-items: center;
}
.content.makeBox .showBox .demoBox .textBox {
  width: 16px;
  font-size: 16px;
  color: #666;
  writing-mode: tb;
  text-align: center;
  letter-spacing: 3px;
}
.content.makeBox .showBox .demoBox .showMake {
  position: relative;
  width: 195px;
  height: 380px;
  overflow: hidden;
  margin: 0 78px;
}
.content.makeBox .showBox .demoBox .showMake .phoneBox,
.content.makeBox .showBox .demoBox .showMake .setPhone {
  width: 195px;
  height: 380px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
}
.content.makeBox .showBox .demoBox .showMake .phoneBox img,
.content.makeBox .showBox .demoBox .showMake .setPhone img {
  width: 195px;
  height: 380px;
}
.content.makeBox .showBox .demoBox .showMake .setPhone {
  z-index: 4;
  cursor: pointer;
  transform-origin: 97.5px 50%;
}
.content.makeBox .showBox .demoBox .showMake .imageBox {
  width: 195px;
  height: 380px;
}
.content.makeBox .showBox .demoBox .showMake .imageBox img {
  height: 380px;
  transform-origin: 97.5px 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.content.makeBox .showBox .demoBox .showMake .imageBox img.change {
  height: auto;
}
.content.makeBox .btnBox {
  text-align: center;
  margin-top: 30px;
}
.content.makeBox .btnBox button {
  width: 410px;
  height: 54px;
  background: linear-gradient(-75deg, #67DAF6, #5DC7FE);
  box-shadow: 0px 2px 10px 0px rgba(141, 216, 255, 0.43);
  border-radius: 27px;
  border: none;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}
.content.makeBox .loadBox .loadBj {
  position: fixed;
  left: 0;
  top: 0;
  background: #eee;
  opacity: 0.2;
  width: 100%;
  height: 100%;
  z-index: 5;
}
.content.makeBox .loadBox .load {
  width: 132px;
  height: 132px;
  background: #000000;
  opacity: 0.8;
  border-radius: 12px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 6;
  display: flex;
  align-items: center;
}
.content.makeBox .loadBox .load p {
  width: 100%;
  font-size: 14px;
  color: #fff;
  text-align: center;
}
.content.small {
  width: 958px;
  height: 564px;
  overflow: hidden;
}
.content.small .showBox {
  height: 371px;
}

.qr-code{
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  left: 0;
  top: 0;
  z-index: 1000;
  user-select: none;
  .code-mask{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.2);
  }
  .code-content{
    .close{
      position: absolute;
      width: 24px;
      height: 24px;
      background: url('../../assets/images/close.png') left top no-repeat;
      background-size: 24px;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 635px;
    height: 323px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
    p{
      font-size: 18px;
      text-align: center;
      line-height: 28px;
      margin-top: 18px;
      color: #7f7e83;
    }
    .code-bg{
      position: relative;
      width: 450px;
      height: 202px;
      background: url('../../assets/images/sao-bg.png') left top no-repeat;
      background-size: 450px;
      margin: 8px auto 0;
      .code-image{
        position: absolute;
        width: 200px;
        height: 200px;
        left: 140px;
        top: 12px;
        img{
          width: 100%;
          &.load{
            display: block;
            width: 50%;
            margin: 50px auto 0;
            animation: load 2s linear infinite;
          }
        }
      }
    }
  }
}

.small{
  .code-content{
    width: 500px;
    height: 300px;
    p{
      font-size: 16px;
    }
    .code-bg{
      width: 400px;
      height: 180px;
      background-size: 400px;
      .code-image{
        width: 170px;
        left: 125px;
      }
    }
  }
}

@keyframes load{
  0%{
    transform: rotate(0deg);
  }
  50%{
    transform: rotate(180deg);
  }
  100%{
    transform: rotate(360deg);
  }
}
.addAddress {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.addAddress .showAddress {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 607px;
  height: 420px;
  background: #fff url(//cdn-ali-file-shfront.shanhutech.cn/front/web/2bg_1617074168936.png) no-repeat left top;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  z-index: 3;
}
.addAddress .showAddress .addressBox {
  position: relative;
}
.addAddress .showAddress .addressBox .closeBox {
  position: absolute;
  top: 8px;
  right: 15px;
}
.addAddress .showAddress .addressBox .closeBox span {
  display: inline-block;
  width: 22px;
  height: 22px;
  background: url(//cdn-ali-file-shfront.shanhutech.cn/front/web/close_1616034371273.png) no-repeat;
  cursor: pointer;
}
.addAddress .showAddress .addressBox .closeBox span:hover {
  background-position-x: -22px;
}
.addAddress .showAddress .addressBox .titleBox {
  padding-top: 24px;
}
.addAddress .showAddress .addressBox .titleBox h3 {
  text-align: center;
  font-size: 20px;
  color: #000;
  font-weight: 400;
}
.addAddress .showAddress .addressBox .formBox {
  width: 540px;
  margin: 0 auto;
  margin-top: 44px;
}
.addAddress .showAddress .addressBox .formBox .group {
  margin-bottom: 15px;
}
.addAddress .showAddress .addressBox .formBox .group label {
  font-size: 15px;
  color: #333;
  display: inline-block;
  width: 65px;
  text-align: right;
  margin-right: 20px;
  font-weight: 600;
}
.addAddress .showAddress .addressBox .formBox .group input {
  width: 301px;
  height: 34px;
  background: #FFFFFF;
  border: 1px solid #DADADA;
  border-radius: 2px;
  padding: 0 10px;
}
.addAddress .showAddress .addressBox .formBox .group select {
  width: 131px;
  height: 34px;
  background: #FFFFFF;
  border: 1px solid #DADADA;
  border-radius: 2px;
  margin-right: 5px;
}
.addAddress .showAddress .addressBox .formBox .group select.province {
  width: 174px;
}
.addAddress .showAddress .addressBox .formBox .group textarea {
  width: 448px;
  padding: 5px 10px;
  height: 71px;
  background: #FFFFFF;
  border: 1px solid #DADADA;
  border-radius: 2px;
}
.addAddress .showAddress .addressBox .formBox .group button {
  width: 260px;
  height: 40px;
  display: block;
  background: #4CC4FF;
  border-radius: 20px;
  border: none;
  font-size: 17px;
  color: #fff;
  margin: 0 auto;
  cursor: pointer;
}
.addAddress .showAddress .addressBox .formBox .group span.red {
  color: #ff0000;
  font-size: 12px;
}

.App{
  width: 1280px;
  height: 750px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  font-family: "microsoft yahei",Helvetica,Tahoma,Arial,"Microsoft jhengHei",sans-serif;
  &.small{
    width: 958px;
    height: 564px;
  }
  .scroll-box{
    width: 100%;
    height: calc(100% - 55px);
    margin-top: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar{
      width: 8px;
    }
    &::-webkit-scrollbar-thumb{
      border-radius:10px;
      -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,.1);
      background-color:#555;
    }
    &::-webkit-scrollbar-track{
      -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.1);
    border-radius:10px;
    background-color:#F5F5F5;
    }
  }
}

.showAlert {
  padding: 10px 15px;
  background: rgba(0, 0, 0, .7);
  color: #fff;
  font-size: 14px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  border-radius: 8px;
}
.resultBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.resultBox .iconBox {
  text-align: center;
}
.resultBox .iconBox p {
  text-align: center;
  font-size: 20px;
  color: #000;
  margin-top: 25px;
}
.resultBox .otherBox {
  text-align: center;
  margin-top: 54px;
}
.resultBox .otherBox .colorText {
  color: #4CC4FF;
  font-size: 20px;
  margin-bottom: 25px;
}
.resultBox .otherBox .weBox p {
  color: #000;
  font-size: 20px;
}
.resultBox .otherBox .weBox .wechetImg {
  margin-top: 20px;
}
.resultBox .otherBox .weBox .wechetImg img {
  width: 158px;
  height: 158px;
}
.resultBox .otherBox .showStepBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.resultBox .otherBox .showStepBox .lineBox {
  width: 2px;
  height: 242px;
  background: #f0f0f0;
  margin: 0 150px;
  position: relative;
  top: 60px;
}
.resultBox .otherBox .showStepBox p {
  font-size: 20px;
  color: #000;
  margin-bottom: 20px;
  line-height: 25px;
}
.resultBox .otherBox .showStepBox p span.icon {
  display: inline-block;
  width: 74px;
  height: 26px;
  background: url(//cdn-ali-file-shfront.shanhutech.cn/front/web/Step1_1616488784117.png) no-repeat;
  background-size: cover;
}
.resultBox .otherBox .showStepBox p small {
  font-size: 14px;
}
.resultBox .otherBox .showStepBox p small span {
  font-size: 17px;
  color: #EF1E1E;
}
.resultBox .otherBox .showStepBox .showImageBox {
  display: flex;
  justify-content: center;
}
.resultBox .otherBox .showStepBox .showImageBox .showImage {
  width: 185px;
  height: 185px;
  background: #FFFFFF;
  border: 1px solid #FECA59;
  border-radius: 10px 0px 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.resultBox .otherBox .showStepBox .showImageBox .showImage img {
  width: 169px;
  height: 166px;
  border-radius: 10px 0px 0px 10px;
}
.resultBox .otherBox .showStepBox .showImageBox .showImageText {
  width: 35px;
  height: 185px;
  background: #FDB412;
  opacity: 0.7;
  border-radius: 0px 5px 5px 0px;
  font-size: 14px;
  color: #191818;
  padding: 0 10px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.resultBox .otherBox .showStepBox .rightStep span.icon {
  width: 82px;
  background: url(//cdn-ali-file-shfront.shanhutech.cn/front/web/Step2_1616488890347.png) no-repeat;
}
.resultBox .otherBox .showStepBox .rightStep .showImageBox {
  margin-top: 47px;
}
.resultBox .btnBox {
  text-align: center;
  margin-top: 42px;
}
.resultBox .btnBox button {
  width: 410px;
  height: 54px;
  background: #0CC4FF;
  box-shadow: 0px 2px 10px 0px rgba(141, 216, 255, 0.43);
  border-radius: 27px;
  font-size: 20px;
  color: #fff;
  border: none;
  cursor: pointer;
}
.resultBox.small {
  width: 958px;
  height: 564px;
  overflow: hidden;
}
.resultBox.small .iconBox {
  margin-top: 10px;
}
.resultBox.small .iconBox p {
  margin-top: 10px;
}
.resultBox.small .btnBox {
  margin-top: 14px;
}
.resultBox.small .otherBox {
  margin-top: 10px;
}
.resultBox.small .otherBox .colorText {
  margin-bottom: 20px;
}
.resultBox.small .otherBox .showStepBox .lineBox {
  width: 2px;
  height: 202px;
  background: #f0f0f0;
  margin: 0 150px;
  position: relative;
  top: 60px;
}
.resultBox.small .otherBox .showStepBox p {
  font-size: 16px;
  margin-bottom: 10px;
}
.resultBox.small .otherBox .showStepBox img {
  width: 160px;
  height: 160px;
}

.payBox .topBox {
  display: flex;
  justify-content: space-between;
  height: 60px;
  line-height: 60px;
  margin-left: 34px;
}
.payBox .topBox .leftBox {
  display: flex;
  justify-content: flex-start;
}
.payBox .topBox .leftBox .retrunBox a {
  display: flex;
}
.payBox .topBox .leftBox .retrunBox a span {
  margin-top: 18px;
  display: inline-block;
  width: 13px;
  height: 23px;
  background: url(//cdn-ali-file-shfront.shanhutech.cn/front/web/return_1616046027178.png) no-repeat 0;
  cursor: pointer;
}
.payBox .topBox .leftBox .retrunBox a .textBox {
  color: #333;
  font-size: 18px;
  margin-left: 20px;
}
.payBox .topBox .leftBox .retrunBox a:hover span {
  background-position-x: -13px;
}
.payBox .topBox .leftBox .retrunBox a:hover .textBox {
  color: #4cc4ff;
}
.payBox .topBox .rightBox {
  margin-right: 34px;
}
.payBox .topBox .rightBox span {
  display: inline-block;
  width: 113px;
  height: 37px;
  background: url(//cdn-ali-file-shfront.shanhutech.cn/front/web/fanhui_1616046199844_1617074224057.png) no-repeat;
  margin-top: 11.5px;
}
.payBox .topBox .rightBox span:hover {
  background-position-x: -113px;
}
.payBox .showPay {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.payBox .showPay .leftBox {
  padding-right: 100px;
  border-right: 1px dashed #ddd;
}
.payBox .showPay .leftBox .bannerBox {
  width: 450px;
  height: 417px;
  overflow: hidden;
}
.payBox .showPay .leftBox .bannerBox img {
  width: 450px;
  height: 417px;
}
.payBox .showPay .leftBox .showPaySku {
  margin-top: 27px;
}
.payBox .showPay .leftBox .showPaySku .titleBox {
  font-size: 19px;
  color: #000;
}
.payBox .showPay .leftBox .showPaySku .titleBox p {
  border-left: 4px solid #4CC4FF;
  padding-left: 10px;
}
.payBox .showPay .leftBox .showPaySku .skuShowPayBox {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 15px;
}
.payBox .showPay .leftBox .showPaySku .skuShowPayBox .box {
  width: 140px;
  height: 48px;
  background: #FBFBFB;
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  font-size: 16px;
  text-align: center;
  color: #666666;
  line-height: 48px;
  margin-bottom: 12px;
  margin-right: 15px;
  cursor: pointer;
}
.payBox .showPay .leftBox .showPaySku .skuShowPayBox .box.active,
.payBox .showPay .leftBox .showPaySku .skuShowPayBox .box:hover {
  background: #4CC4FF;
  color: #fff;
}
.payBox .showPay .leftBox .showPaySku .skuShowPayBox .box:nth-of-type(3n) {
  margin-right: 0;
}
.payBox .showPay .rightBox {
  width: 526px;
  text-align: right;
}
.payBox .showPay .rightBox .addressBox {
  width: 520px;
  padding-left: 64px;
  height: 77px;
  border-radius: 2px;
  text-align: left;
  line-height: 77px;
  font-size: 20px;
  color: #000;
  background: #F7F7F7 url(//cdn-ali-file-shfront.shanhutech.cn/front/web/icon_1615973219459.png) no-repeat 24px center;
  cursor: pointer;
}
.payBox .showPay .rightBox .addressBox .addressInfoShow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 77px;
}
.payBox .showPay .rightBox .addressBox .addressInfoShow div {
  line-height: 30px;
}
.payBox .showPay .rightBox .addressBox .addressInfoShow div.nickBox {
  font-size: 20px;
  color: #000;
}
.payBox .showPay .rightBox .addressBox .addressInfoShow div.nickBox span {
  color: #666;
  font-size: 16px;
  margin-left: 10px;
}
.payBox .showPay .rightBox .addressBox .addressInfoShow div.addressInfo {
  font-size: 15px;
  color: #666;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 300px;
  overflow: hidden;
}
.payBox .showPay .rightBox .infoBox .group {
  display: flex;
  margin-top: 50px;
}
.payBox .showPay .rightBox .infoBox .group .titleBox,
.payBox .showPay .rightBox .infoBox .group .info {
  font-size: 18px;
  color: #666;
  padding: 0 10px;
}
.payBox .showPay .rightBox .infoBox .group .titleBox ul,
.payBox .showPay .rightBox .infoBox .group .info ul {
  display: flex;
  justify-content: flex-start;
}
.payBox .showPay .rightBox .infoBox .group .titleBox ul li,
.payBox .showPay .rightBox .infoBox .group .info ul li {
  width: 144px;
  height: 38px;
  background: #FBFBFB;
  border: 1px solid #EFEFEF;
  border-radius: 5px;
  line-height: 38px;
  text-align: center;
  margin-left: 13px;
  margin-top: -7px;
  font-size: 16px;
  color: #666;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.payBox .showPay .rightBox .infoBox .group .titleBox ul li img,
.payBox .showPay .rightBox .infoBox .group .info ul li img {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.payBox .showPay .rightBox .infoBox .group .titleBox ul li.active,
.payBox .showPay .rightBox .infoBox .group .info ul li.active,
.payBox .showPay .rightBox .infoBox .group .titleBox ul li:hover,
.payBox .showPay .rightBox .infoBox .group .info ul li:hover {
  border: 1px solid #4CC4FF;
}
.payBox .showPay .rightBox .infoBox .group .titleBox button,
.payBox .showPay .rightBox .infoBox .group .info button {
  width: 37px;
  height: 31px;
  background: #F5F5F5;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
.payBox .showPay .rightBox .infoBox .group .titleBox input.numInput,
.payBox .showPay .rightBox .infoBox .group .info input.numInput {
  height: 31px;
  background: #FFFFFF;
  border: none;
  text-align: center;
  font-size: 17px;
  line-height: 31px;
  width: 70px;
  display: inline-block;
}
.payBox .showPay .rightBox .infoBox .group .titleBox .showPrice,
.payBox .showPay .rightBox .infoBox .group .info .showPrice {
  color: #FF0000;
  font-size: 24px;
}
.payBox .showPay .rightBox .infoBox .group .titleBox .realPrice,
.payBox .showPay .rightBox .infoBox .group .info .realPrice {
  color: #a3a3a3;
  font-size: 17px;
  margin-left: 10px;
  text-decoration: line-through;
}
.payBox .showPay .rightBox .infoBox .group .lineBox {
  flex: 1;
  height: 1px;
  border-top: 1px dashed #ccc;
  margin-top: 10px;
}
.payBox .showPay .rightBox .btnBox {
  margin-top: 54px;
}
.payBox .showPay .rightBox .btnBox button {
  width: 360px;
  height: 50px;
  background: linear-gradient(-51deg, #FF7272, #FF2222);
  box-shadow: 0px 4px 10px 0px rgba(242, 93, 93, 0.61);
  border-radius: 25px;
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}
.payBox .codeBox .codeBj {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.payBox .codeBox .showCode {
  width: 459px;
  height: 317px;
  background: #FFFFFF url(//cdn-ali-file-shfront.shanhutech.cn/front/web/bg_1617012939003.png) no-repeat center top;
  border-radius: 8px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
.payBox .codeBox .showCode .closeBox {
  text-align: right;
  padding-top: 15px;
  padding-right: 15px;
}
.payBox .codeBox .showCode .closeBox span {
  display: inline-block;
  width: 22px;
  height: 22px;
  background: url(//cdn-ali-file-shfront.shanhutech.cn/front/web/close_1616034371273.png) no-repeat;
  cursor: pointer;
}
.payBox .codeBox .showCode .closeBox span:hover {
  background-position-x: -22px;
}
.payBox .codeBox .showCode .codeShow h3 {
  text-align: center;
  font-size: 20px;
  color: #000;
  font-weight: 400;
}
.payBox .codeBox .showCode .codeShow .codeImg {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.payBox .codeBox .showCode .codeShow .codeImg p {
  color: #4CC4FF;
  font-size: 20px;
  margin-top: 20px;
}
.payBox.small {
  width: 958px;
  height: 564px;
  overflow: hidden;
}
.payBox.small .topBox {
  margin-left: 34px;
}
.payBox.small .topBox .retrunBox span {
  margin-top: 18px;
  display: inline-block;
  width: 10px;
  height: 28px;
  background: url(//cdn-ali-file-shfront.shanhutech.cn/front/web/return_1616496653386.png) no-repeat 0;
  cursor: pointer;
}
.payBox.small .topBox .retrunBox span:hover {
  background-position-x: -10px;
}
.payBox.small .topBox .textBox {
  font-size: 18px;
}
.payBox.small .topBox .rightBox {
  margin-right: 34px;
}
.payBox.small .showPay {
  margin-top: 30px;
}
.payBox.small .showPay .leftBox {
  margin-left: 30px;
  padding-right: 50px;
}
.payBox.small .showPay .leftBox .bannerBox {
  width: 365px;
  height: 337px;
}
.payBox.small .showPay .leftBox .bannerBox img {
  width: 365px;
  height: 337px;
}
.payBox.small .showPay .leftBox .showPaySku {
  margin-top: 22px;
}
.payBox.small .showPay .leftBox .showPaySku .titleBox {
  font-size: 15px;
}
.payBox.small .showPay .leftBox .showPaySku .skuShowPayBox {
  margin-top: 15px;
}
.payBox.small .showPay .leftBox .showPaySku .skuShowPayBox .box {
  width: 114px;
  height: 38px;
  line-height: 38px;
  font-size: 13px;
  margin-right: 10px;
}
.payBox.small .showPay .rightBox {
  width: 390px;
  margin-right: 30px;
}
.payBox.small .showPay .rightBox .addressBox {
  width: 390px;
}
.payBox.small .showPay .rightBox .infoBox .titleBox {
  font-size: 15px;
}
.payBox.small .showPay .rightBox .infoBox .group {
  margin-top: 34px;
}
.payBox.small .showPay .rightBox .infoBox .info {
  font-size: 15px;
}
.payBox.small .showPay .rightBox .infoBox .info button {
  height: 26px;
}
.payBox.small .showPay .rightBox .infoBox .info .numInput {
  height: 26px;
  line-height: 26px;
}
.payBox.small .showPay .rightBox .infoBox .info .showPrice {
  font-size: 20px;
}
.payBox.small .showPay .rightBox .infoBox .info .realPrice {
  font-size: 14px;
  text-decoration: line-through;
}
.payBox.small .showPay .rightBox .infoBox .info ul li {
  width: 120px;
  height: 32px;
  line-height: 32px;
  font-size: 14px;
}
.payBox.small .showPay .rightBox .btnBox {
  margin-top: 40px;
}
